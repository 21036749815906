import { Actions, Mutations } from '@/config/store.config'
import { CartProduct } from '@/models/cart/cart.model'
import Product from '@/models/menu/product.model'
import { Dictionary, Nullable } from '@/utils/customtypes.util'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import PlaceInputRequest from '@/models/requests/place-input.request'

export enum Dialogs {
  Error = 'errorDialogId',
  ProductDetail = 'productDetailId',
  Tip = 'tipId'
}

export interface ErrorDialogState {
  title: string
  icon?: string
  message: string
  callback?: Function
}

export interface ProductDetailDialogState {
  product: Product
  orderProduct?: CartProduct,
  divisionGroupId?: number
}

export interface TipDialogState {
  total: number
  tip: Nullable<number>
  callback?: Function
}

export interface DialogState {
  visibleDialog: Nullable<Dialogs>
  visibleError: Nullable<Dialogs>,

  metadata: Dictionary<any>
}

@Module
export default class DialogModule extends VuexModule implements DialogState {
  visibleDialog: Nullable<Dialogs>
  visibleError: Nullable<Dialogs>

  metadata: Dictionary<any> = {}

  private getMetadata<T> (id: Dialogs): Nullable<T> {
    return this.metadata[id] as Nullable<T>
  }

  get isVisible () : (id: Dialogs) => boolean {
    return (id: Dialogs) => {
      return id === this.visibleDialog
    }
  }

  get isErrorVisible () : (id: Dialogs) => boolean {
    return (id: Dialogs) => {
      return id === this.visibleError
    }
  }

  get dialogData () : (id: Dialogs) => Nullable<any> {
    return (id: Dialogs) => {
      return this.metadata[id] as Nullable<any>
    }
  }

  @Mutation
  [Mutations.SET_VISIBLE_DIALOG] ({
    dialogId,
    metadata
  }: {
    dialogId: Dialogs
    metadata: any
  }): void {
    if (!dialogId) {
      return
    }

    this.metadata[dialogId] = metadata
    this.visibleDialog = dialogId
  }

  @Mutation
  [Mutations.SET_VISIBLE_ERROR_DIALOG] ({
    dialogId,
    metadata
  }: {
    dialogId: Dialogs
    metadata: any
  }): void {
    if (!dialogId) {
      return
    }

    this.metadata[dialogId] = metadata
    this.visibleError = dialogId
  }

  @Mutation
  [Mutations.HIDE_ALL_DIALOG] () : void {
    this.visibleDialog = null
  }

  @Mutation
  [Mutations.HIDE_ALL_ERRORS] () : void {
    this.visibleError = null
  }

  @Action
  [Actions.SHOW_PRODUCT_DETAIL] ({ product, orderProduct, divisionGroupId } : { product: Product, orderProduct?: CartProduct, divisionGroupId?: number }): void {
    const metadata: ProductDetailDialogState = {
      product,
      orderProduct,
      divisionGroupId
    }

    this.context.commit(Mutations.SET_VISIBLE_DIALOG, {
      dialogId: Dialogs.ProductDetail,
      metadata: metadata
    })
  }

  @Action
  [Actions.SHOW_TIP] ({ total, tip, callback } : { total: number, tip: Nullable<number>, callback?: Function}): void {
    const metadata: TipDialogState = {
      total,
      tip,
      callback
    }

    this.context.commit(Mutations.SET_VISIBLE_DIALOG, {
      dialogId: Dialogs.Tip,
      metadata: metadata
    })
  }

  @Action
  [Actions.SHOW_ERROR] ({ title, icon, message, callback } : {
    title: string,
    icon?: string
    message: string,
    callback?: Function
  }): void {
    const metadata: ErrorDialogState = {
      title,
      icon,
      message,
      callback
    }

    this.context.commit(Mutations.SET_VISIBLE_ERROR_DIALOG, {
      dialogId: Dialogs.Error,
      metadata: metadata
    })
  }

  @Action
  [Actions.HIDE_ALL_DIALOG] (): void {
    this.context.commit(Mutations.HIDE_ALL_DIALOG)
  }

  @Action
  [Actions.HIDE_ALL_ERRORS] (): void {
    this.context.commit(Mutations.HIDE_ALL_ERRORS)
  }
}
